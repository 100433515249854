define("ember-css-modules/helpers/local-class", ["exports", "@ember/component/helper", "@ember/debug", "require"], function (_exports, _helper, _debug, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.localClass = localClass;

  function localClass(params, hash) {
    (false && !('from' in hash) && (0, _debug.assert)('No source specified to local-class lookup', 'from' in hash));

    if (!hash.from) {
      return '';
    }

    let styles = resolveSource(hash.from);
    let classes = (params[0] || '').split(/\s+/);
    return classes.map(style => styles[style]).filter(Boolean).join(' ');
  }

  var _default = (0, _helper.helper)(localClass);

  _exports.default = _default;

  function resolveSource(source) {
    if (typeof source === 'string') {
      if (_require.default.has(source)) {
        return (0, _require.default)(source).default;
      } else {
        throw new Error(`Unable to resolve local class names from ${source}; does the styles file exist?`);
      }
    } else {
      return source;
    }
  }
});